export const merchantServicesSection2Mixin = {
    data() {
        return {
            dotPosition: ["dot-1.svg", "dot-2.svg", "dot-3.svg", "dot-1.svg"],
            leftPosition: [
                "right-1.png",
                "right-2.png",
                "right-3.png",
                "right-4.png",
                "right-5.png",
                "right-6.png",
                "right-7.png",
            ],
            rightPosition: [
                "logo.png",
                "left-1.png",
                "left-2.png",
                "left-3.png",
                "left-4.png",
                "left-5.png",
                "left-6.png",
                "left-7.png",
                "left-8.png",
            ],
        };
    },
    computed: {
        position() {
            return [
                ...this.dotPosition.map((item, i) => ({
                    className: `evo-dot-${i + 1}`,
                    src: require(`@/assets/home-merchant/brand/${item}`),
                })),
                ...this.leftPosition.map((item, i) => ({
                    className: `evo-left-${i + 1}`,
                    src: require(`@/assets/home-merchant/brand/${item}`),
                })),
                ...this.rightPosition.map((item, i) => {
                    return {
                        className: `evo-right-${i + 1}` + (i === 0 ? " no-animation" : ""),
                        src: require(`@/assets/home-merchant/brand/${item}`),
                    };
                }),
            ];
        },
    },
}

import { PaymentBrand, PaymentCountries } from "@/data/payment-methods";
export const merchantServicesSection3Mixin = {
    data: () => ({
        model: null,
        features: [
            {
                icon: require("@/assets/home-merchant/s3-icon-1.png"),
                title: "home_merchant.online_payment",
                desc: "home_merchant.online_payment_desc",
            },
            {
                icon: require("@/assets/home-merchant/s3-icon-2.png"),
                title: "home_merchant.omni_channel",
                desc: "home_merchant.omni_channel_desc",
            },
            {
                icon: require("@/assets/home-merchant/s3-icon-3.png"),
                title: "home_merchant.unifed_processing",
                desc: "home_merchant.unifed_processing_desc",
            },
            {
                icon: require("@/assets/home-merchant/s3-icon-4.png"),
                title: "home_merchant.transaction_protection",
                desc: "home_merchant.transaction_protection_desc",
            },
        ],
        items: [
            "Alipay",
            "WeChatPay",
            "KakaoPay",
            "NaverPay",
            "PromptPay",
            "TrueMoney",
            "RabbitLinePay",
            "Boost",
            "TouchN",
            "Konbini",
            "PayPay",
            "RakutenPay",
            "GrabPay",
            "DANA",
            "GCash",
        ],
        // items中对应的country
        countries: [
            "cn",
            "cn",
            "kr",
            "kr",
            "th",
            "th",
            "th",
            "my",
            "my",
            "jp",
            "jp",
            "jp",
            "sg",
            "id",
            "ph",
        ],
        brands: [],
    }),
    created() {
        this.brands = this.items.map((key, index) => {
            const brand = PaymentBrand[key];
            const countryLocation = this.$t("home_merchant.asia_pacific");
            const countryFull = PaymentCountries[this.countries[index]];
            return {
                id: key,
                logo: brand.logo,
                active: index === 0,
                activeInfo: {
                    logo: brand.smallLogo,
                    desc: brand.longDesc,
                    countryLocation,
                    country: countryFull.name,
                    countryIconfont: countryFull.iconfont,
                },
            };
        });
    },
    methods: {
        onToggle(toggle) {
            toggle();
        },
        isSmallIcon(brand) {
            return ["LCC", "Konbini", "BankTransfer"].indexOf(brand) > -1;
        },
        gotoBrand(brand) {
            this.$router.push({ path: `/payment-methods/${brand.id}` });
        },
        gotoMore() {
            this.$router.push({ path: "/payment-methods" });
        },
    },
}

import EvoEllipse from '@/components/Ellipse.vue';
export const merchantServicesSection4Mixin = {
    components: {
        EvoEllipse,
    },
    data() {
        return {
            others: {
                title: "home_merchant.s4_others_title",
                items: [
                    "home_merchant.s4_others_item1",
                    "home_merchant.s4_others_item2",
                    "home_merchant.s4_others_item3",
                    "home_merchant.s4_others_item4",
                ],
            },
            ours: {
                title: "home_merchant.s4_ours_title",
                items: [
                    "home_merchant.s4_ours_item1",
                    "home_merchant.s4_ours_item2",
                    "home_merchant.s4_ours_item3",
                    "home_merchant.s4_ours_item4",
                ],
                keywords: [
                    "Support simple and lightweight integration",
                    "Flexible business processes configuration",
                    "Local product and technical support teams",
                    "Integrate with multiple payment channels",
                ],
            },
        };
    },
    computed: {
        //  Our solutions 的高亮文本
        highlightItems() {
            const { items, keywords } = this.ours;
            return items.map((item, i) => {
                const str = this.$t(item);
                return str.replace(
                    new RegExp(keywords[i], "g"),
                    '<span class="highlights-text">' + keywords[i] + "</span>"
                );
            });
        },
    },
}