<template>
  <div class="section-2">
    <div class="evo-bg">
      <img loading="lazy" v-for="pos in position" :src="pos.src" :key="pos.className" :class="pos.className" class="evo-icon" />
    </div>
  </div>
</template>
<script>
import { merchantServicesSection2Mixin } from '@/mixins/merchant-services.js';
export default {
  name: 'EvoHomeMerchantSectionTwo',
  mixins: [merchantServicesSection2Mixin]
};
</script>
<style lang="scss" scoped>
$padding-top: 80px;
$padding-bottom: 40px;
// width top left
$dotPosition: (11 318 16), (18 476 346), (8 333 699), (11 480 841);
$leftPosition: (67 240 99), (56 346 100), (70 332 234), (56 306 346), (100 478 25), (74 437 184), (71 513 343);
$rightPosition: (114 359 477), (71 351 683), (56 265 754), (88 284 841), (56 341 994), (56 533 628), (74 440 709), (56 419 880), (100 517 915);
.section-2 {
  position: relative;
  height: calc(664px + #{$padding-top} + #{$padding-bottom});
  .evo-bg {
    position: absolute;
    left: 0;
    top: $padding-top;
    right: 0;
    bottom: $padding-bottom;
    background-image: url('~@/assets/home-merchant/s2-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;

    & > img {
      animation: moveUpDown 1.5s infinite ease-in-out alternate;
      box-shadow: 0px 17px 68px 0px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      &.no-animation {
        animation: none;
        box-shadow: none;
        border-radius: 0;
      }
    }
  }

  .evo-icon {
    position: absolute;
    height: auto;
  }

  @for $i from 1 through length($dotPosition) {
    $item: nth($dotPosition, $i);
    .evo-dot-#{$i} {
      width: nth($item, 1) * 1px;
      top: nth($item, 2) * 1px;
      left: nth($item, 3) * 1px;
    }
  }
  @for $i from 1 through length($leftPosition) {
    $item: nth($leftPosition, $i);
    .evo-left-#{$i} {
      width: nth($item, 1) * 1px;
      top: nth($item, 2) * 1px;
      left: nth($item, 3) * 1px;
      animation-delay: random(10) * 0.1s !important;
    }
  }
  @for $i from 1 through length($rightPosition) {
    $item: nth($rightPosition, $i);
    .evo-right-#{$i} {
      width: nth($item, 1) * 1px;
      top: nth($item, 2) * 1px;
      left: nth($item, 3) * 1px;
      animation-delay: random(10) * 0.1s !important;
    }
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}
</style>
