<template>
  <div class="evo-home-merchant-wrapper">
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMMerchantServicesSectionOne />
      <EvoMMerchantServicesSectionTwo />
      <EvoMMerchantServicesSectionThree />
      <EvoMMerchantServicesSectionFour />
      <EvoMWalletNetworkSectionEight />
      <EvoMMainSectionTwo />
    </template>
    <template v-else>
      <EvoHomeMerchantSectionOne />
      <EvoHomeMerchantSectionTwo />
      <EvoHomeMerchantSectionThree />
      <EvoHomeMerchantSectionFour />
      <EvoHomeSectionEight />
      <EvoMainSectionTwo />
    </template>
  </div>
</template>
<script>
import EvoHomeMerchantSectionOne from '@/components/merchant_services_sections/Section_1.vue';
import EvoHomeMerchantSectionTwo from '@/components/merchant_services_sections/Section_2.vue';
import EvoHomeMerchantSectionThree from '@/components/merchant_services_sections/Section_3.vue';
import EvoHomeMerchantSectionFour from '@/components/merchant_services_sections/Section_4.vue';
import EvoHomeSectionEight from '@/components/wallet_network_sections/Section_8.vue';
import EvoMainSectionTwo from '@/components/main_sections/Section_2.vue';

// mobile imports
import EvoMMerchantServicesSectionOne from '@/mobile/components/merchant_services_sections/MSection_1.vue';
import EvoMMerchantServicesSectionTwo from '@/mobile/components/merchant_services_sections/MSection_2.vue';
import EvoMMerchantServicesSectionThree from '@/mobile/components/merchant_services_sections/MSection_3.vue';
import EvoMMerchantServicesSectionFour from '@/mobile/components/merchant_services_sections/MSection_4.vue';
import EvoMMainSectionTwo from '@/mobile/components/main_sections/MSection_2.vue';
import EvoMWalletNetworkSectionEight from '@/mobile/components/wallet_network_sections/MSection_8.vue';
export default {
  name: 'EvoHomeMerchant',
  components: {
    EvoHomeMerchantSectionOne,
    EvoHomeMerchantSectionTwo,
    EvoHomeMerchantSectionThree,
    EvoHomeMerchantSectionFour,
    EvoHomeSectionEight,
    EvoMainSectionTwo,
    EvoMMerchantServicesSectionOne,
    EvoMMerchantServicesSectionTwo,
    EvoMMerchantServicesSectionThree,
    EvoMMerchantServicesSectionFour,
    EvoMMainSectionTwo,
    EvoMWalletNetworkSectionEight
  }
};
</script>
<style lang="scss" scoped>
.evo-home-merchant-wrapper {
  background: #fafafa;
}
</style>
