import { render, staticRenderFns } from "./MSection_2.vue?vue&type=template&id=3c74128b&scoped=true"
import script from "./MSection_2.vue?vue&type=script&lang=js"
export * from "./MSection_2.vue?vue&type=script&lang=js"
import style0 from "./MSection_2.vue?vue&type=style&index=0&id=3c74128b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_d4ca1f2c239379901a21149caec87237/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c74128b",
  null
  
)

export default component.exports