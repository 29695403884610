<template>
  <div class="section-1 section-1-bg evo-ph-176 evo-pv-60">
    <div data-aos="fade-up" data-aos-duration="1000" class="evo-title">
      {{ $t('home_merchant.s1_title') }}
    </div>
    <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" class="evo-egms">
      <img src="@/assets/home-merchant/EGMS-logo.png" />
    </div>
    <div data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000" class="evo-sub-title evo-opensans-regular">
      {{ $t('home_merchant.s1_sub_title') }}
    </div>
    <div class="d-flex justify-center">
      <router-link to="contact-us" class="router-link">
        <v-btn data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" rounded x-large class="text-none evo-contact-us">
          {{ $t('contact_us') }}
        </v-btn>
      </router-link>
    </div>
    <div data-aos="fade-up">
      <div class="evo-multiple-devices">&nbsp;</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EvoHomeMerchantSectionOne'
};
</script>
<style lang="scss" scoped>
.section-1 {
  background-color: $nav_color;
  position: relative;
  padding-top: 48px !important;
  height: 120vh;

  .evo-title {
    text-align: center;
    font-size: 38px;
    color: #ffffff;
    width: 600px;
    margin: 32px auto 0;
  }

  .evo-egms {
    text-align: center;
    margin-top: 16px;
    & > img {
      width: 187px;
    }
  }

  .evo-sub-title {
    font-size: 15px;
    color: #ffffff;
    width: 700px;
    margin: 16px auto 0;
    text-align: center;
  }

  .evo-contact-us {
    @include whiteblue_btn;
    width: 140px;
    margin-top: 48px;
  }

  .evo-multiple-devices {
    background-image: url('~@/assets/home-merchant/s1-multi-devices.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 8vw;
    margin-left: 52%;
    transform: translateX(-50%);
    width: 703px;
    height: 398px;
    z-index: 2;
  }
}
</style>
