<template>
  <div class="section-2">
    <div class="evo-bg">
      <img loading="lazy" v-for="pos in position" :src="pos.src" :key="pos.className" :class="pos.className" class="evo-icon" />
    </div>
  </div>
</template>
<script>
import { merchantServicesSection2Mixin } from '@/mixins/merchant-services.js';
export default {
  name: 'EvoMMerchantServicesSectionTwo',
  mixins: [merchantServicesSection2Mixin]
};
</script>
<style lang="scss" scoped>
$padding-top: 80px;
$padding-bottom: 80px;
// width top left
$dotPosition: (24 112 396), (36 902 286), (18 1684 582), (24 2334 218);
$leftPosition: (198 164 482), (218 242 90), (168 400 306), (178 642 100), (178 624 482), (168 922 90), (218 902 462);
$rightPosition: (302 1324 236), (178 1684 60), (168 1784 380), (168 1964 528), (178 2064 64), (198 2264 458), (218 2464 128), (198 2664 508), (238 2864 118);
.section-2 {
  position: relative;
  height: calc(664px + #{$padding-top} + #{$padding-bottom});
  min-height: 456vw;

  .evo-bg {
    position: absolute;
    left: 0;
    top: 200px;
    right: 0;
    bottom: 200px;
    background-image: url('~@/assets/home-merchant/s2-bg-mobile.svg');
    background-position: 33% 100%;
    background-repeat: no-repeat;
    background-size: cover;

    & > img {
      animation: moveUpDown 1.5s infinite ease-in-out alternate;
      box-shadow: 0px 17px 68px 0px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      &.no-animation {
        animation: none;
        box-shadow: none;
        border-radius: 0;
      }
    }
  }

  .evo-icon {
    position: absolute;
    height: auto;
  }

  @for $i from 1 through length($dotPosition) {
    $item: nth($dotPosition, $i);
    .evo-dot-#{$i} {
      width: nth($item, 1) * 1px;
      top: nth($item, 2) * 1px;
      left: nth($item, 3) * 1px;
    }
  }
  @for $i from 1 through length($leftPosition) {
    $item: nth($leftPosition, $i);
    .evo-left-#{$i} {
      width: nth($item, 1) * 1px;
      top: nth($item, 2) * 1px;
      left: nth($item, 3) * 1px;
      animation-delay: random(10) * 0.1s !important;
    }
  }
  @for $i from 1 through length($rightPosition) {
    $item: nth($rightPosition, $i);
    .evo-right-#{$i} {
      width: nth($item, 1) * 1px;
      top: nth($item, 2) * 1px;
      left: nth($item, 3) * 1px;
      animation-delay: random(10) * 0.1s !important;
    }
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}
</style>
