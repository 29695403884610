<template>
  <div
    class="section-3 evo-ph-206"
    :class="{ 'section-3-lg': $vuetify.breakpoint.lgAndDown }"
  >
    <div class="evo-bg"></div>
    <div class="evo-title evo-text-26" data-aos="fade-up">
      {{ $t("home_merchant.s3_title") }}
    </div>
    <div
      class="evo-sub-title evo-text-14 evo-opensans-regular"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      {{ $t("home_merchant.s3_sub_title") }}
    </div>
    <v-row class="evo-items" justify="space-between">
      <v-col
        class="px-0"
        cols="12"
        sm="12"
        md="3"
        v-for="(item, index) in features"
        :key="index"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <v-row class="evo-item" justify="center">
          <v-col>
            <img loading="lazy"  :src="item.icon" />
            <div class="evo-item-title">
              {{ $t(item.title) }}
            </div>
            <div class="evo-item-desc evo-opensans-regular">
              {{ $t(item.desc) }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div
      class="evo-payment-method-title evo-text-28"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      {{ $t("home_merchant.payment_methods") }}
    </div>
    <div
      class="evo-payment-method-sub-title evo-text-14 evo-opensans-regular"
      data-aos="fade-up"
      data-aos-delay="250"
    >
      {{ $t("home_merchant.payment_methods_desc") }}
    </div>
    <!-- payment methods -->
    <div class="evo-payment-method" data-aos="fade-up" data-aos-delay="300">
      <v-slide-group mandatory show-arrows="always">
        <v-slide-item
          v-for="item in brands"
          :key="item.id"
          v-slot="{ active, toggle }"
        >
          <div class="evo-slide-wrapper">
            <div class="evo-slide-active" v-if="active">
              <div class="d-flex">
                <img loading="lazy" 
                  class="evo-logo"
                  :class="{ small: isSmallIcon(item.id) }"
                  :src="item.activeInfo.logo"
                />
              </div>
              <div class="evo-desc evo-text-12 evo-opensans-regular">
                <span>{{ $t(item.activeInfo.desc) }}</span>
              </div>
              <div class="evo-country">
                <span
                  v-if="item.activeInfo.countryIconfont"
                  class="fi fis evo-logo mr-4"
                  :class="item.activeInfo.countryIconfont"
                ></span>
                <div class="evo-text-wrapper evo-text-10">
                  <span class="evo-location">
                    {{ item.activeInfo.countryLocation }}
                  </span>
                  <span class="evo-name">
                    {{ item.activeInfo.country }}
                  </span>
                </div>
              </div>
              <div class="evo-arrow-right-wrapper" @click="gotoBrand(item)">
                <img loading="lazy"  class="evo-arrow-right" src="@/assets/arrow_right.svg" />
              </div>
            </div>
            <div
              class="evo-slide-inactive"
              v-if="!active"
              @click="onToggle(toggle)"
            >
              <img loading="lazy"  class="evo-logo" :src="item.logo" />
            </div>
          </div>
        </v-slide-item>
        <v-slide-item key="more">
          <div class="evo-slide-wrapper">
            <div class="evo-slide-inactive">
              <div class="evo-more-wrapper" @click="gotoMore()">
                <img loading="lazy" 
                  class="evo-logo"
                  src="@/assets/home-merchant/more-right.png"
                />
                <span class="evo-text evo-text-20 evo-opensans-regular">
                  {{ $t("home_merchant.more") }}
                </span>
              </div>
            </div>
          </div>
        </v-slide-item>
        <template #prev>
          <img loading="lazy"  src="@/assets/home-merchant/arrow-left-white.svg" />
        </template>
        <template #next>
          <img loading="lazy" 
            src="@/assets/home-merchant/arrow-left-white.svg"
            style="transform: rotate(-180deg)"
          />
        </template>
      </v-slide-group>
    </div>
  </div>
</template>
<script>
import { merchantServicesSection3Mixin } from '@/mixins/merchant-services.js';
export default {
  name: "EvoHomeMerchantSectionThree",
  mixins: [merchantServicesSection3Mixin]
};
</script>
<style lang="scss" scoped>
.section-3 {
  position: relative;
  overflow: hidden;
  // height: 152vh;
  padding-top: 0;
  // max-height: 800px;

  .evo-title {
    color: #222a41;
    text-align: center;
  }

  .evo-sub-title {
    color: #a0a3a8;
    line-height: 43px;
    margin-bottom: 42px;
    text-align: center;
  }

  .evo-items {
    position: relative;
    margin-left: -14px;
    margin-right: -14px;
    margin-bottom: 80px;
    .evo-item {
      text-align: center;
      min-height: 200px;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      margin: 0 8px;

      & img {
        width: 52px;
        height: auto;
      }

      & div {
        text-align: center;
      }

      .evo-item-title {
        font-size: 11px;
        color: #222a41;
        margin-bottom: 16px;
      }

      .evo-item-desc {
        font-size: 10px;
        color: #6d7783;
      }
    }
  }

  .evo-payment-method-title {
    position: relative;
    margin-left: -136px;
    color: #ffffff;
    margin-bottom: 8px;
  }

  .evo-payment-method-sub-title {
    position: relative;
    margin-left: -136px;
    margin-bottom: 66px;
    color: #fefefe;
    // line-height: 43px;
    white-space: pre-wrap;
  }

  .evo-payment-method {
    position: relative;
    margin-left: -206px;
    margin-right: -206px;
    margin-bottom: 142px;

    .evo-slide-wrapper {
      position: relative;
      margin-right: 30px;
      background: transparent;
      display: flex;
      align-items: flex-end;
      height: 245px;

      &:nth-child(1) {
        margin-left: 70px;
      }

      .evo-slide-active {
        background: #ffffff;
        box-shadow: 0px 17px 34px 0px rgba(0, 0, 0, 0.2),
          0px 8px 17px 0px rgba(69, 158, 255, 0.1);
        border-radius: 15px;
        width: 286px;
        height: 245px;
        padding: 32px 16px;
        position: relative;
        transition: all 0.15s ease-in;
        .evo-logo {
          width: 123px;
          &.small {
            width: auto;
            height: 38px;
          }
        }

        .evo-desc {
          min-height: 90px;
          margin-top: 16px;
          width: 100%;
          font-weight: normal;
          color: #6d7783;
          white-space: break-spaces;
          word-break: break-word;
          overflow-wrap: break-word;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          -ms-text-overflow: ellipsis;
          text-overflow: ellipsis;
        }

        .evo-country {
          margin-top: 24px;
          display: flex;

          .evo-logo {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            box-shadow: 0px 17px 34px 0px rgba(0, 0, 0, 0.2),
              0px 8px 17px 0px rgba(69, 158, 255, 0.1);
          }

          .evo-text-wrapper {
            display: flex;
            flex-direction: column;
            .evo-location {
              font-weight: 600;
              color: #000000;
            }
            .evo-name {
              font-weight: 600;
              color: #6d7783;
            }
          }
        }

        .evo-arrow-right-wrapper {
          position: absolute;
          right: 16px;
          bottom: 12px;
          cursor: pointer;
          .evo-arrow-right {
            width: auto;
          }
        }
      }
      .evo-slide-inactive {
        background: #ffffff;
        box-shadow: 0px 17px 34px 0px rgba(0, 0, 0, 0.2),
          0px 8px 17px 0px rgba(69, 158, 255, 0.1);
        border-radius: 15px;
        width: 233px;
        height: 203px;
        cursor: pointer;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-out;
        .evo-logo {
          width: 155px;
        }
      }

      .evo-more-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        white-space: normal;

        .evo-logo {
          width: 37px;
          margin-bottom: 6px;
          user-select: none;
        }

        .evo-text {
          width: 180px;
          font-weight: 600;
          color: #438cff;
          line-height: 28px;
          text-align: center;
        }
      }
    }
  }

  ::v-deep(.v-slide-group__prev) {
    position: absolute;
    right: 160px;
    bottom: -50px;
  }

  ::v-deep(.v-slide-group__next) {
    position: absolute;
    right: 100px;
    bottom: -50px;
  }

  .evo-bg {
    background-image: url("~@/assets/home-merchant/s3-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 78%;
    z-index: 0;
  }
}
</style>
