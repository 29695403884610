<template>
  <div class="section-3 evo-f-center">
    <div class="evo-mobile">
      <div class="evo-bg"></div>
      <div class="evo-title evo-text-58">
        {{ $t('home_merchant.s3_title') }}
      </div>
      <div class="evo-sub-title evo-text-30 evo-opensans-regular">
        {{ $t('home_merchant.s3_sub_title') }}
      </div>
      <v-row class="evo-items" justify="space-between">
        <v-col class="px-0" cols="12" sm="12" md="3" v-for="(item, index) in features" :key="index">
          <div class="evo-item">
            <img loading="lazy" :src="item.icon" />
            <div class="evo-item-title evo-text-51">
              {{ $t(item.title) }}
            </div>
            <div class="evo-item-desc evo-text-38 evo-opensans-regular">
              {{ $t(item.desc) }}
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="evo-payment-method-title evo-text-58">
        {{ $t('home_merchant.payment_methods') }}
      </div>
      <div class="evo-payment-method-sub-title evo-text-24 evo-opensans-regular">
        {{ $t('home_merchant.payment_methods_desc') }}
      </div>
      <!-- payment methods -->
      <div class="evo-payment-method">
        <v-slide-group center-active mandatory show-arrows="always">
          <v-slide-item v-for="item in brands" :key="item.id" v-slot="{ active, toggle }">
            <div class="evo-slide-wrapper">
              <div class="evo-slide-active" v-if="active">
                <div class="d-flex">
                  <img loading="lazy" class="evo-logo" :class="{ small: isSmallIcon(item.id) }" :src="item.activeInfo.logo" />
                </div>
                <div class="evo-desc evo-text-24 evo-opensans-regular">
                  <span>{{ $t(item.activeInfo.desc) }}</span>
                </div>
                <div class="evo-country">
                  <span v-if="item.activeInfo.countryIconfont" class="fi fis evo-logo mr-4" :class="item.activeInfo.countryIconfont"></span>
                  <div class="evo-text-wrapper evo-text-10">
                    <span class="evo-location evo-text-24">
                      {{ item.activeInfo.countryLocation }}
                    </span>
                    <span class="evo-name evo-text-22">
                      {{ item.activeInfo.country }}
                    </span>
                  </div>
                </div>
                <div class="evo-arrow-right-wrapper" @click="gotoBrand(item)">
                  <img loading="lazy" class="evo-arrow-right" src="@/assets/arrow_right.svg" />
                </div>
              </div>
              <div class="evo-slide-inactive" v-if="!active" @click="onToggle(toggle)">
                <img loading="lazy" class="evo-logo" :src="item.logo" />
              </div>
            </div>
          </v-slide-item>
          <v-slide-item key="more">
            <div class="evo-slide-wrapper">
              <div class="evo-slide-inactive">
                <div class="evo-more-wrapper" @click="gotoMore()">
                  <img loading="lazy" class="evo-logo" src="@/assets/home-merchant/more-right.png" />
                  <span class="evo-text evo-text-20 evo-opensans-regular">
                    {{ $t('home_merchant.more') }}
                  </span>
                </div>
              </div>
            </div>
          </v-slide-item>
          <template #prev>
            <img loading="lazy" src="@/assets/home-merchant/arrow-left-white.svg" />
          </template>
          <template #next>
            <img loading="lazy" src="@/assets/home-merchant/arrow-left-white.svg" style="transform: rotate(-180deg)" />
          </template>
        </v-slide-group>
      </div>
    </div>
  </div>
</template>
<script>
import { merchantServicesSection3Mixin } from '@/mixins/merchant-services.js';
export default {
  name: 'EvoMMerchantServicesSectionThree',
  mixins: [merchantServicesSection3Mixin]
};
</script>
<style lang="scss" scoped>
.section-3 {
  position: relative;
  overflow: hidden;
  padding-top: 0;

  .evo-title {
    color: #222a41;
    text-align: center;
    margin-bottom: 8px;
  }

  .evo-sub-title {
    color: #a0a3a8;
    margin-bottom: 42px;
    text-align: center;
  }

  .evo-items {
    position: relative;
    margin-left: -14px;
    margin-right: -14px;
    margin-bottom: 80px;
    .evo-item {
      text-align: center;
      min-height: 200px;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      padding: 16px 48px 48px 48px;
      margin: 0 auto;
      width: 654px;

      & img {
        width: 192px;
        height: auto;
      }

      & div {
        text-align: center;
      }

      .evo-item-title {
        color: #222a41;
        margin-bottom: 16px;
      }

      .evo-item-desc {
        color: #6d7783;
      }
    }
  }

  .evo-payment-method-title {
    position: relative;
    color: #ffffff;
    margin-bottom: 8px;
  }

  .evo-payment-method-sub-title {
    position: relative;
    margin-bottom: 66px;
    color: #fefefe;
    white-space: pre-line;
  }

  .evo-payment-method {
    position: relative;
    margin-bottom: 142px;

    .evo-slide-wrapper {
      position: relative;
      margin-right: 30px;
      background: transparent;
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 400px;

      &:nth-last-child(1) {
        margin-right: 0;
      }

      .evo-slide-active {
        background: #ffffff;
        box-shadow: 0px 17px 34px 0px rgba(0, 0, 0, 0.2), 0px 8px 17px 0px rgba(69, 158, 255, 0.1);
        border-radius: 15px;
        width: 460px;
        height: 400px;
        padding: 32px 16px;
        position: relative;
        transition: all 0.15s ease-in;
        .evo-logo {
          width: 144px;
          &.small {
            width: auto;
            height: 44px;
          }
        }

        .evo-desc {
          min-height: 90px;
          margin-top: 16px;
          width: 100%;
          font-weight: normal;
          color: #6d7783;
          white-space: break-spaces;
          word-break: break-word;
          overflow-wrap: break-word;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          -ms-text-overflow: ellipsis;
          text-overflow: ellipsis;
        }

        .evo-country {
          margin-top: 24px;
          display: flex;

          .evo-logo {
            width: 72px;
            height: 72px;
            border-radius: 50%;
            box-shadow: 0px 17px 34px 0px rgba(0, 0, 0, 0.2), 0px 8px 17px 0px rgba(69, 158, 255, 0.1);
          }

          .evo-text-wrapper {
            display: flex;
            flex-direction: column;
            .evo-location {
              font-weight: 600;
              color: #000000;
            }
            .evo-name {
              font-weight: 600;
              color: #6d7783;
            }
          }
        }

        .evo-arrow-right-wrapper {
          position: absolute;
          right: 16px;
          bottom: 12px;
          cursor: pointer;
          .evo-arrow-right {
            width: auto;
          }
        }
      }
      .evo-slide-inactive {
        background: #ffffff;
        box-shadow: 0px 17px 34px 0px rgba(0, 0, 0, 0.2), 0px 8px 17px 0px rgba(69, 158, 255, 0.1);
        border-radius: 15px;
        width: 320px;
        height: 260px;
        cursor: pointer;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-out;
        .evo-logo {
          width: 298px;
        }
      }

      .evo-more-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        white-space: normal;

        .evo-logo {
          width: 74px;
          margin-bottom: 6px;
          user-select: none;
        }

        .evo-text {
          width: 180px;
          font-weight: 600;
          color: #438cff;
          line-height: 28px;
          text-align: center;
        }
      }
    }
  }

  ::v-deep(.v-slide-group__prev) {
    position: absolute;
    right: 360px;
    bottom: -100px;
  }

  ::v-deep(.v-slide-group__next) {
    position: absolute;
    right: 200px;
    bottom: -100px;
  }

  .evo-bg {
    background-image: url('~@/assets/home-merchant/s3-bg-mobile.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 10vw;
    border-top-right-radius: 10vw;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 420vw;
    z-index: 0;
  }
}
</style>
