<template>
  <div class="section-4 evo-ph-206 evo-pv-60">
    <div class="evo-text-34 evo-title" data-aos="fade-up">
      {{ $t('home_merchant.s4_title') }}
    </div>
    <div class="evo-text-14 evo-opensans-regular evo-sub-title" data-aos="fade-up" data-aos-delay="100">
      {{ $t('home_merchant.s4_sub_title') }}
    </div>
    <v-row class="evo-solutions" align="center" justify="center">
      <v-col cols="12" sm="12" md="6" class="pl-0 pr-8">
        <div class="evo-others" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
          <div class="evo-others-title evo-text-18 evo-opensans-regular evo-mb-32">
            {{ $t(others.title) }}
          </div>
          <div v-for="(item, index) in others.items" :key="index" class="evo-mb-16 evo-item-text evo-text-9 evo-opensans-regular font-weight-bold">
            <v-row>
              <v-col cols="12" sm="1" md="1">
                <v-icon color="#FF6767" class="evo-mr-8">$vuetify.icons.mdiCloseCircleOutline</v-icon>
              </v-col>
              <v-col cols="12" sm="11" md="11"
                ><span>{{ $t(item) }}</span></v-col
              >
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="pr-0 pr-8">
        <div class="evo-ours" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
          <div class="evo-ours-title evo-text-20 evo-opensans-regular evo-mb-32">
            {{ $t(ours.title) }}
          </div>
          <div v-for="(item, index) in highlightItems" :key="index" class="evo-mb-16 evo-item-text evo-text-10 evo-opensans-regular font-weight-bold">
            <v-row>
              <v-col cols="12" sm="1" md="1">
                <v-icon color="#18E480" class="evo-mr-8">$vuetify.icons.mdiCheckCircleOutline</v-icon>
              </v-col>
              <v-col cols="12" sm="11" md="11">
                <span v-html="item"></span>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <EvoEllipse class="evo-s4-ellipse" background="lightYellow" transform="rotate(0deg)" filter="blur(25px)" width="150px" height="150px" />
  </div>
</template>
<script>
import { merchantServicesSection4Mixin } from '@/mixins/merchant-services.js';
export default {
  name: 'EvoHomeMerchantSectionFour',
  mixins: [merchantServicesSection4Mixin]
};
</script>
<style lang="scss" scoped>
.section-4 {
  position: relative;
  background: $header_bg_color;

  .evo-title {
    color: #222a41;
    line-height: 43px;
    text-align: center;
    margin-bottom: 8px;
  }

  .evo-sub-title {
    color: #a0a3a8;
    line-height: 23px;
    margin-bottom: 48px;
    text-align: center;
  }

  .evo-solutions {
    .evo-others,
    .evo-ours {
      border-radius: 17px;
      border: 1px solid #dddfe7;
      padding: 32px 24px;
    }

    .evo-ours {
      background: #ffffff;
      box-shadow: 30px 30px 59px 0px rgba(0, 0, 0, 0.1);
    }

    .evo-others-title,
    .evo-ours-title {
      text-align: center;
    }

    .evo-others-title {
      color: #b4b4b4;
    }

    .evo-ours-title {
      color: #3f47f2;
    }

    .evo-others > .evo-item-text {
      color: #b4b4b4;
    }
  }

  .evo-s4-ellipse {
    position: absolute;
    left: 120px;
    top: 80px;
    z-index: 0;
  }
}

::v-deep(.highlights-text) {
  color: #3f47f2;
}
</style>
