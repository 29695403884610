<template>
  <div class="section-4 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <div class="evo-text-58 evo-title">
        {{ $t('home_merchant.s4_title') }}
      </div>
      <div class="evo-text-30 evo-opensans-regular evo-sub-title">
        {{ $t('home_merchant.s4_sub_title') }}
      </div>
      <v-row class="evo-solutions" align="center" justify="center">
        <v-col cols="12">
          <div class="evo-others">
            <div class="evo-others-title evo-text-40 evo-opensans-regular">
              {{ $t(others.title) }}
            </div>
            <div v-for="(item, index) in others.items" :key="index" class="evo-opensans-regular font-weight-bold">
              <div class="evo-item-wrapper">
                <v-icon color="#FF6767" class="evo-mr-8">$vuetify.icons.mdiCloseCircleOutline</v-icon>
                <span class="evo-text-22 evo-item-text">{{ $t(item) }}</span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="evo-ours">
            <div class="evo-ours-title evo-text-40 evo-opensans-regular">
              {{ $t(ours.title) }}
            </div>
            <div v-for="(item, index) in highlightItems" :key="index" class="evo-opensans-regular font-weight-bold">
              <div class="evo-item-wrapper">
                <v-icon color="#18E480" class="evo-mr-8">$vuetify.icons.mdiCheckCircleOutline</v-icon>
                <span v-html="item" class="evo-text-22 evo-item-text"></span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <EvoEllipse class="evo-s4-ellipse" background="lightYellow" transform="rotate(0deg)" filter="blur(25px)" width="150px" height="150px" />
    </div>
  </div>
</template>
<script>
import { merchantServicesSection4Mixin } from '@/mixins/merchant-services.js';
export default {
  name: 'EvoMMerchantServicesSectionFour',
  mixins: [merchantServicesSection4Mixin]
};
</script>
<style lang="scss" scoped>
.section-4 {
  position: relative;
  background: $header_bg_color;

  .evo-title {
    color: #222a41;
    text-align: center;
    margin-bottom: 8px;
  }

  .evo-sub-title {
    color: #a0a3a8;
    margin-bottom: 64px;
    text-align: center;
  }

  .evo-solutions {
    .evo-others,
    .evo-ours {
      border-radius: 17px;
      border: 1px solid #dddfe7;
      padding: 32px;
      width: 668px;
      margin: auto;
    }

    .evo-ours {
      background: #ffffff;
      box-shadow: 30px 30px 59px 0px rgba(0, 0, 0, 0.1);
    }

    .evo-others-title,
    .evo-ours-title {
      text-align: center;
      margin-bottom: 64px;
    }

    .evo-others-title {
      color: #b4b4b4;
    }

    .evo-ours-title {
      color: #3f47f2;
    }

    .evo-others .evo-item-text {
      color: #b4b4b4;
    }

    .evo-item-wrapper {
      display: flex;
      margin-bottom: 64px;
      align-items: flex-start;
    }
  }

  .evo-s4-ellipse {
    position: absolute;
    left: 120px;
    top: 80px;
    z-index: 0;
  }
}

::v-deep(.highlights-text) {
  color: #3f47f2;
}
</style>
