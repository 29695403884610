<template>
  <div class="section-1 evo-f-center section-1-bg evo-pv-60">
    <div class="evo-mobile">
      <div class="evo-title evo-text-62">
        {{ $t('home_merchant.s1_title') }}
      </div>
      <div class="evo-egms">
        <img loading="lazy" src="@/assets/home-merchant/EGMS-logo.png" />
      </div>
      <div class="evo-sub-title evo-text-32 evo-opensans-regular">
        {{ $t('home_merchant.s1_sub_title') }}
      </div>
      <div class="d-flex justify-center">
        <router-link to="contact-us" class="router-link">
          <v-btn rounded x-large class="text-none evo-contact-us">
            <span class="evo-text-29">{{ $t('contact_us') }}</span>
          </v-btn>
        </router-link>
      </div>
      <div>
        <div class="evo-multiple-devices">&nbsp;</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EvoMMerchantServicesSectionOne'
};
</script>
<style lang="scss" scoped>
.section-1 {
  position: relative;
  background-color: $nav_color;
  position: relative;
  padding-top: 48px !important;
  height: 180vw;

  .evo-title {
    text-align: center;
    color: #ffffff;
    width: 600px;
    margin: 32px auto 64px;
  }

  .evo-egms {
    text-align: center;
    margin-bottom: 48px;
    & > img {
      width: 400px;
      height: auto;
    }
  }

  .evo-sub-title {
    color: #ffffff;
    width: 700px;
    margin: 16px auto 0;
    text-align: center;
    margin-bottom: 96px;
  }

  .evo-contact-us {
    @include whiteblue_btn;
    min-width: 280px;
    margin-bottom: 96px;
  }

  .evo-multiple-devices {
    position: relative;
    background-image: url('~@/assets/home-merchant/s1-multi-devices.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 96%;
    height: 524px;
    margin: 0 auto;
    z-index: 2;
  }
}
</style>
